var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Button Toolbar with Dropdown" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeDropdown) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-button-toolbar",
        {
          staticClass: "demo-inline-spacing",
          attrs: {
            "aria-label": "Toolbar with button groups and dropdown menu"
          }
        },
        [
          _c(
            "b-button-group",
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_vm._v(" New ")]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_vm._v(" Edit ")]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_vm._v(" Undo ")]
              )
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { right: "", text: "menu", variant: "outline-primary" }
            },
            [
              _c("b-dropdown-item", [_vm._v("Item 1")]),
              _c("b-dropdown-item", [_vm._v("Item 2")]),
              _c("b-dropdown-item", [_vm._v("Item 3")])
            ],
            1
          ),
          _c(
            "b-button-group",
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_vm._v(" Save ")]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_vm._v(" Cancel ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }