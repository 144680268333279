var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c("button-toolbar-navigation"),
          _c("button-toolbar-input"),
          _c("button-toolbar-dropdown"),
          _c("button-toolbar-size"),
          _c("button-toolbar-justify")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }