var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Size" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeSize) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            "If you want smaller or larger buttons or controls, set the size prop directly on the "
          )
        ]),
        _c("code", [_vm._v("<b-button-group>")]),
        _c("span", [_vm._v(", ")]),
        _c("code", [_vm._v("<b-input-group>")]),
        _c("span", [_vm._v(", and ")]),
        _c("code", [_vm._v("<b-dropdown>")]),
        _c("span", [_vm._v(" components")])
      ]),
      _c(
        "b-button-toolbar",
        [
          _c(
            "b-button-group",
            { attrs: { size: "lg" } },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_vm._v(" First ")]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_vm._v(" Second ")]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_vm._v(" Third ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-button-toolbar",
        [
          _c(
            "b-button-group",
            { staticClass: "my-1" },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_vm._v(" First ")]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_vm._v(" Second ")]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_vm._v(" Third ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-button-toolbar",
        [
          _c(
            "b-button-group",
            { attrs: { size: "sm" } },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_vm._v(" First ")]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_vm._v(" Second ")]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_vm._v(" Third ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }