var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Justify" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeJustify) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            "Make the toolbar span the maximum available width, by increasing spacing between the button groups, input groups and dropdowns, by setting the prop "
          )
        ]),
        _c("code", [_vm._v("justify")]),
        _c("span", [_vm._v(".")])
      ]),
      _c(
        "b-button-toolbar",
        { attrs: { justify: "" } },
        [
          _c(
            "b-button-group",
            { staticClass: "mr-2 mb-1" },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_vm._v(" Button ")]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_vm._v(" Button ")]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_vm._v(" Button ")]
              )
            ],
            1
          ),
          _c(
            "b-button-group",
            { staticClass: "mr-2 mb-1" },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_c("feather-icon", { attrs: { icon: "HomeIcon" } })],
                1
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_c("feather-icon", { attrs: { icon: "SearchIcon" } })],
                1
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_c("feather-icon", { attrs: { icon: "BellIcon" } })],
                1
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" }
                },
                [_c("feather-icon", { attrs: { icon: "ShoppingCartIcon" } })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }